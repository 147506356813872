<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row no-gutters class="mb-2">
      <v-col>
        <div class="headline">
          <v-icon large>mdi-eye-check-outline</v-icon>
          Observability Queries
        </div>
      </v-col>
      <v-col class="text-right
      ">
        <table-filter-dialog />
        <v-btn small color="info" class="ml-2" @click="createEditShow()">
          <v-icon small>mdi-plus</v-icon>
          New Observability Query
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between" no-gutters>
      <delete-dialog />
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="ma-2 elevation-2">
                <!-- Header with count in a chip -->
                <v-card-title class="justify-space-between">
                  {{ item.name }}
                </v-card-title>

                <!-- Content -->
                <v-card-text>
                  <div>
                    <v-icon small left>mdi-account-arrow-right</v-icon>
                    <strong>Created:</strong> {{ item.created_at }}
                  </div>
                  <div>
                    <v-icon small left>mdi-folder-multiple-outline</v-icon>
                    <strong>Project:</strong> {{ item.project.name }}
                  </div>
                  <div>
                    <v-icon small left>mdi-axis-arrow-info</v-icon>
                    <strong>Description:</strong> {{ item.description }}
                  </div>
                  <div>
                    <v-icon small left color="secondary">mdi-language-javascript</v-icon>
                    <strong>Language:</strong> {{ item.language }}
                  </div>
                </v-card-text>

                <!-- Actions -->
                <v-card-actions>
                  <!--Item Count in a-->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="item.count > 10"
                        label
                        v-bind="attrs"
                        v-on="on"
                        class="mr-3"
                      >
                        <v-icon left x-small>mdi-domain</v-icon>
                        {{ item.count }}
                      </v-chip>
                      <v-chip
                        v-else
                        label
                        v-bind="attrs"
                        v-on="on"
                        class="mr-3"
                      >
                        <v-icon left x-small>mdi-domain</v-icon>
                        {{ item.count }}
                      </v-chip>
                      </template>
                      <span>{{ item.count }} times mentioned</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="showText(item.text)">
                        <v-icon>mdi-magnify-scan</v-icon>
                      </v-btn>
                    </template>
                    <span>View Query Text</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="createEditShow(item)">
                        <v-icon>mdi-data-matrix-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Query</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="removeShow(item)">
                        <v-icon>mdi-data-matrix-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Query</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <!-- Dialog for showing text -->
          <v-dialog v-model="isTextViewDialogOpen" max-width="600px">
            <v-card>
              <v-card-title class="text-h5">
                Query Text
                <v-spacer></v-spacer>
                <v-btn
                icon
                @click="copyToClipboard(currentViewingText)"
                class="ml-4"
              >
                <v-icon left>mdi-content-copy</v-icon>
              </v-btn>
              </v-card-title>
              <v-card-text>{{ currentViewingText }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeTextDialog">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/data/query/DeleteDialog.vue"
import NewEditSheet from "@/data/query/NewEditSheet.vue"
import RouterUtils from "@/router/utils"
import ServicePopover from "@/service/ServicePopover.vue"
import TableFilterDialog from "@/data/query/TableFilterDialog.vue"
import ProjectCard from "@/project/ProjectCard.vue"

export default {
  name: "QueryTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    ServicePopover,
    TableFilterDialog,
    ProjectCard,
  },

  data() {
    return {
      headers: [],
      currentViewingText: '',
      isTextViewDialogOpen: false,
    }
  },

  computed: {
    ...mapFields("query", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.tag",
      "table.options.filters.tag_type",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("query", ["getAll", "createEditShow", "removeShow", "copyToClipboardSuccess"]),
    showText(text) {
      this.currentViewingText = text
      this.isTextViewDialogOpen = true
    },
    closeTextDialog() {
      this.isTextViewDialogOpen = false
      this.currentViewingText = ''
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copyToClipboardSuccess()
      })
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project, vm.tag, vm.tag_type],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>
