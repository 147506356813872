<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12>
        <v-select :items="languages" v-model="language" label="Language"></v-select>
      </v-flex>
      <v-flex xs12>
        <div style="height: 100vh">
          <MonacoEditor v-model="text" :options="editorOptions" :language="language"></MonacoEditor>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"

export default {
  name: "QueryTextTab",

  components: {
    MonacoEditor: () => import("monaco-editor-vue"),
  },

  computed: {
    ...mapFields("query", ["selected.text", "selected.language", "selected.loading"]),
  },

  data() {
    return {
      languages: [
        "sql",
        "graphql",
        "sparql",
        "cypher",
        "elasticsearch query dsl",
        "kusto query language (kql)",
        "mongodb query language",
        "promql",
        "hiveql",
        "json",
        "postgresql (psql)",
        "mysql",
        "sqlite",
        "oracle sql",
        "pl/sql",
        "t-sql",
        "kafka query language (ksql)",
        "apache pig latin",
        "cassandra query language (cql)"
      ],
      editorOptions: {
        automaticLayout: true,
        renderValidationDecorations: "on",
      },
    }
  },
}
</script>
